import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { Heading } from '../../components';

import css from './ListingPage.module.css';
import { getDefaultLocale, isArrayLength, isLocaleGreek } from '../../util/genericHelpers';
import { EXPERTISE_OPTIONS, GROUPED_EXPERTISE } from '../../config/customConfig';

const SectionDetailsMaybe = props => {
  const { publicData, intl, currentLocaleFromStorage } = props;

  const expertise =
    (currentLocaleFromStorage === 'en' ? publicData?.en?.expertise : publicData?.expertise) || [];
  if (expertise.length === 0) {
    return <></>;
  }
  const isGreek = isLocaleGreek();
  const expertiseTitle = intl.formatMessage({ id: 'ListingPage.expertiseTitle' });

  const groupedExpertise = GROUPED_EXPERTISE?.flatMap(g => g.options)
    .filter((v, i, a) => a?.findIndex(v2 => v2?.value === v?.value) === i)
    .map(l => {
      return {
        value: l.value,
        label: isGreek ? l.greekLabel : l.label,
        isExpertise: true,
      };
    });

  return (
    <div className={css.sectionDetails}>
      <h2>{expertiseTitle}</h2>
      {isArrayLength(expertise)
        ? expertise.map((e, i) => {
            const option = groupedExpertise.find(o => o.value === e);

            if (!option) {
              return <></>;
            }
            return (
              <span className={css.expertisePills} key={i}>
                {option?.label}
              </span>
            );
          })
        : null}
    </div>
  );
};

export default SectionDetailsMaybe;
